/* eslint-disable object-curly-newline */

import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import { getVideosPaged } from '@api'
import fetchPaged from '@core/utils/fetchPaged'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'
import { isObjEmpty } from '@core/utils'

export default function useVideoList() {
  const { userData } = useCryptoJs()
  const { t } = useUtils()
  const { hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  const listTable = ref([])
  const tableColumns = computed(() => [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
      show: true,
    },
    { text: t('facilities.facility').toUpperCase(), value: 'facility_name', show: true },
    { text: t('transactions.video_date').toUpperCase(), value: 'video_date', show: true },
    { text: t('transactions.start_time').toUpperCase(), value: 'start_time', show: true },
    { text: t('transactions.video_time').toUpperCase(), value: 'video_time', show: true, sortable: false },
    { text: t('status.status').toUpperCase(), value: 'status_str', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasPermission(16),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))
  const totalListTable = ref(0)

  const filters = ref(null)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const descSort = ref([])
  const loading = ref(false)
  const timerSearch = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)

  const searchQuery = ref('')
  const statusFilter = ref(null)
  const dateFrom = ref(null)
  const dateTo = ref(null)

  const maxDate = ref(
    new Date(new Date()).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const fetchPagedVideos = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    if (statusFilter.value) filterOption.status = statusFilter.value
    if (configFacility.value) filterOption.facility_id = configFacility.value

    if (userData.value && userData.value.role !== 'A') filterOption.group_id = userData.value.group_id
    else if (configOrganization.value) filterOption.group_id = configOrganization.value
    else filterOption.group_id = null

    if (dateFrom.value) filterOption.start_date = dateFrom.value
    if (dateTo.value) filterOption.end_date = dateTo.value
    filters.value = filterOption
    const response = await fetchPaged(getVideosPaged, options.value, filterOption)

    listTable.value = response.data
    totalListTable.value = response.total
    loading.value = false
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      updateFilter('views-video-list', 'search', searchQuery.value)
      await fetchPagedVideos()
    }, time)
  }

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await getDataBySearch()
    }
  })

  watch([configOrganization, configFacility], async () => {
    statusFilter.value = null
    dateFrom.value = null
    dateTo.value = null
    searchQuery.value = ''
  })

  watch([statusFilter, configOrganization, configFacility, dateFrom, dateTo], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1

    // await fetchPagedVideos()
    await getDataBySearch(300)
  })

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    await getDataBySearch()
  })

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.status) statusFilter.value = value.status
      if (value.facility_id) configFacility.value = value.facility_id
      if (value.start_date) dateFrom.value = value.start_date
      if (value.end_date) dateTo.value = value.end_date
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Video') return 'primary'
    if (status === 'R' || status === 'C' || status === 'I') return 'error'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'P') return 'warning'
    if (status === 'S') return 'info'
    if (status === 'A') return 'success'

    return 'secondary'
  }

  return {
    searchQuery,
    options,
    descSort,
    filters,
    hasOptionsFilter,
    listTable,
    statusFilter,
    totalListTable,
    loading,
    computedTableColumns,
    maxDate,
    dateFrom,
    dateTo,
    userData,

    configOrganization,
    configFacility,

    // i18n
    t,

    resolveClientAvatarVariant,
    hasPermission,
    fetchPagedVideos,
    getDataBySearch,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
